module.exports = new Promise(resolve => {
                    const urlHost = window.location.host;
                    const isProd = urlHost === 'offers.arrivelogistics.com';
                    const isStg = urlHost === 'al-offersmanagement-standalone-stg-central.azurewebsites.net';
                    const isTest = urlHost === 'al-offersmanagement-standalone-test-central.azurewebsites.net';

                    const devSource = "https://aloffersui.z21.web.core.windows.net/remoteEntry.js";
                    const testSource = "https://aloffersuitestcentral.z19.web.core.windows.net/remoteEntry.js";
                    const stgSource = "https://aloffersuistgcentral.z19.web.core.windows.net/remoteEntry.js";
                    const prodSource = "https://aloffersuiprodcentral.z19.web.core.windows.net/remoteEntry.js";

                    const script = document.createElement('script')
                    
                    let sourceToUse = devSource;
                    
                    if (isProd) {
                        sourceToUse = prodSource;
                    } else if (isStg) {
                        sourceToUse = stgSource;
                    } else if (isTest) {
                        sourceToUse = testSource;
                    }
            
                    script.src = sourceToUse;

                    script.onload = () => {
                        const proxy = {
                            get: (request) => window.OffersUI.get(request),
                            init: (arg) => {
                                try {
                                    return window.OffersUI.init(arg)
                                } catch(e) {
                                    console.log('OffersUI container already initialized')
                                }
                            }
                        }
                        resolve(proxy)
                    }
                    document.head.appendChild(script);
                });